.footerText {
    padding-top: 20px;
    color: gray;
    display: inline-block;
   
}

.footerLeft{
   background-color: #343a40;
    color: white;
}
.footerRight{
    padding-top: 2%;
    background-color: #343a40;
    color: white;
    text-align: right;
}
.footerFlagImg{
   
        display: block;
        background-color: #FF851B;
        max-width:50px;
        max-height:auto;
        width: auto;
        height: auto;
        margin-bottom: 5%;
        margin-top: 5%;

}

@media only screen and (max-width: 768px) {
    .footerRight{
        padding-top: 2%;
        background-color:  #343a40;
        color: white;
        text-align: left;
    }
  
}
.feedbackButton {
    padding-top: 10px;
    padding-bottom: 10px;
}