.videoWrapper {
    position: relative;
    height: 0;
    padding-bottom: calc(var(--aspect-ratio, .5625) * 100%); 

  }
  .videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

.videoModal {
    background: rgb(0, 0, 0, .8);


}
.videoButton {
    font-size: 4rem;
    color: #fff;
}
.videoButton:hover {
    font-size: 4rem;
    color: #ff9800;
}
.closeButton {
    position: absolute;
    top: -20%;
    right: -10%;
    cursor: pointer;
}

.videoImg {
    position: relative;
    padding: 5px;
    border-bottom-left-radius: 5%;
    border-top-right-radius: 5%;
    border-bottom-right-radius: 5%;
    border-top-left-radius: 5%;


}

.videoImg:hover {
    background: #ff9800;
    cursor: pointer;
}

.videoGalWrapper {
    padding: 50px;
}