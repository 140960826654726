@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.contentContainer{
  margin-top: 60px;

}
.nav
{  
    opacity: 0.9;  
    width: 100%;   
    z-index: 2;   
    position: fixed;
    top: 0;
    background-color: #d7dbddaa;
    
}

.navItem {
    font-size: 18px;
}
.navItem:hover {
    color: #FF851B;
}

.countryLogo {
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 50%;
    border: 3px solid rgba(0, 0, 0, 0);
    
}
.countryLogo:hover {
    border: 3px solid #FF851B;
    cursor: pointer;
    
}

.scountryLogo {
    margin: 10px;
    border-radius: 50%;

    
}
.scountryLogo:hover {
    border: 3px solid #FF851B;
    cursor: pointer;
    
}

.countryModalTitle{
    font-family: sans-serif;
    text-align: center;
    padding-bottom: 20px;
    padding-top: 20px;
    background-color: #d7dbdd;
    color: #6c757d;
    font-weight: bolder;
    font-family: sans-serif;
}

.countryModalBody{
    text-align: center;
    
}



alertMessage {
    position: fixed;
}

.headingText {
    padding-top: 20px;
}
.contactButton {
    margin-left: 10px;
    border-radius: 50px !important;
    height: 40px;
    background-color: #6c757d;
    border-color: #6c757d;
    font-size: 12px;
    font-weight: bolder;
    text-align: left;
}

.contactButton:hover {
    background-color: #FF851B;
    border-color: #FF851B;
}

.contactContainer{
    margin-left: 2%;
    margin-right: 2%;
}


.contactSubmitButton{
    margin-top: 20px;
    text-align: center;
    background-color: #6c757d;
    border-color: #6c757d;
    color: white;
    cursor: pointer;
}

.contactSubmitButton:hover{
    margin-top: 20px;
    text-align: center;
    background-color: #FF851B;
    border-color: #FF851B;
    color: white;
}

.contactModalTitle {
    text-align: center;
    background-color: #d7dbdd;
    color: #6c757d;
    padding-top: 2%;
    padding-bottom: 2%;
    font-family: sans-serif;
    font-weight: bolder;
}

.disabledtrue{
    cursor: not-allowed;
    pointer-events: none;
    color: #d7dbdd;
    background-color: #d7dbdd;
}
.closeButton {

    cursor: pointer;
}

.launchTc {
    display: inline;
    color: #6c757d;
    cursor: pointer;

}
.launchTc:hover {

    color: #FF851B;


}
.tcContainer{
    font-family: sans-serif;
}

ol {
    counter-reset: item
  }
  li {
    display: block
  }
  li:before {
    content: counters(item, ".") " ";
    counter-increment: item
  }
  



.footerText {
    padding-top: 20px;
    color: gray;
    display: inline-block;
   
}

.footerLeft{
   background-color: #343a40;
    color: white;
}
.footerRight{
    padding-top: 2%;
    background-color: #343a40;
    color: white;
    text-align: right;
}
.footerFlagImg{
   
        display: block;
        background-color: #FF851B;
        max-width:50px;
        max-height:auto;
        width: auto;
        height: auto;
        margin-bottom: 5%;
        margin-top: 5%;

}

@media only screen and (max-width: 768px) {
    .footerRight{
        padding-top: 2%;
        background-color:  #343a40;
        color: white;
        text-align: left;
    }
  
}
.feedbackButton {
    padding-top: 10px;
    padding-bottom: 10px;
}
.rowSpacing{
    padding: 50px auto;
    margin: 20px auto;
    text-align: justify;;
}
.headingText{
    color: #0d47a1;
}
.aboutParagraph{

}
.countryName {
    font-weight: bold;
    text-transform: uppercase;
    color: #0074D9;
}

.headingText {
    color: #0074D9;
    text-transform: uppercase;
}

.line{
    border: 1px solid #FF851B;
    width: 100px;
    margin-top: 0;
}

.address {
    font-style: italic;
    color: rgba(0, 0, 0, 0.7);
    font-family: sans-serif;
}

.addressPadding{
  padding-top: 1em;
}

.mapBorder{
  border: 1px solid rgba(0,0,0,0.1);
}

.topHeading {
    padding: 20px;
}

.customPadding {
    padding: 20px;
}

.cardsMargin{
    margin: 1em 0;
}

.marginSpace{
    margin-top: 2em;
    margin-bottom: 2em;
    margin-left: auto;
    margin-right: auto;
    padding-top: 2em;
    padding-bottom: 3em;
}

.contactImage {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    background-image: url("/assets/Ashram_India.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.rowPadding {
    padding: auto;
    margin: 0;
}

.headingMargin{
    margin-top: 30px;
}

.cardsPadding {
    margin-left: auto;
    margin-right: auto;
    padding-top: 1em;
    padding-bottom: 4em;
}

.centerText {
    text-align: center;
}

.contactFormPadding{
    margin-left: auto;
    margin-right: auto;
    padding-top: 2em;
    padding-bottom: 8em;
}



.formStyle{
    width: 50%;
}

.orangeBack {
    background-color: #FF851B;
}

.silverBack{
    background-color:  #d7dbdd  ;
}

.bg-branches{
    background-color: #f6ddcc;
}

.imgNoSpace {
    margin: 0;
    padding: 0;
}

.bg-contact{
    background-color: rgba(0, 0, 0, 0.1);
}

.btn-color {
    background-color: #FF851B;
    color: #fff;
}
.countryName{
    color: #0d47a1;
    text-align: left;
    text-transform: uppercase;
    
}
.lineSep {
    background-color: #0d47a1;
    width:50%;
    text-align:center;
}
.flagDiv{
    object-fit: cover;
    margin-right: 50px;
    margin-top: 5px;

}

.flagImg{
    display: block;
    background-color: #FF851B;
    max-width:150px;
    max-height:auto;
    width: auto;
    height: auto;
   
}

.disabledCountryGallery {
    opacity: 0.2;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 50px;
    width: 50px;
    height: 50px;

}
.disabledCountryGallery:hover {
    border-color: #fff;
    cursor: not-allowed;
}


.eventGalImg {
    width: 100%;
    height: auto;
    display: block;
    padding: 5px;
    background: #fff;
    box-sizing: border-box;

}

.eventGalImg:hover {
    background: #ff9800;
    cursor: pointer;
}

.imageContainer {
    -webkit-column-count: 6;
            column-count: 6;
    grid-column-gap: 5px;
    -webkit-column-gap: 5px;
            column-gap: 5px;
    margin: 0 auto;
    background: #fff;;
    padding-left: 10%;
    padding-right: 10%; 
}


.hide{
    display:none;
}
.unhide{
    display: inherit;
}

.imgCountry {
    cursor: pointer;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 50px;
    width: 50px;
    height: 50px;
}
.imgCountry:hover {
    cursor: pointer;
    border: 3px solid #FF851B;
}
.alignRight {
    text-align: right;
}
.imgCountryHeading {
    font-family: sans-serif;
    text-transform: uppercase;
    letter-spacing: 5px;
    font-weight: 800;
    color: #0d47a1;
    align-content: center;
    margin: 50px;
}
.photoModal {
    background: rgb(0, 0, 0, .8);

}
.modalPhoto {
    width: 100%;
    height: auto;
}
.photoButton {
    font-size: 6rem;
    color: #fff;
}
.photoModalBody{
    background: transparent;
}
.leftArrow {
    position: absolute;
    top: 45%;
    left: -20%;
    cursor: pointer;
}
.rightArrow {
    position: absolute;
    top: 45%;
    right: -20%;
    cursor: pointer;
}
.photoCaption{
    position: absolute;
    top: 100%;
    color: #fff;
    cursor: pointer;
    text-align: center;
    font-size: 1.5rem;
}
.photoButton:hover {
    color: #ff9800;
}
.headingText {
    color: #0d47a1; 
}
.topPad {
    padding-top: 50px;
}



/*Grid Layout for tiny screens*/
@media only screen and (max-width: 512px)  {
    [class*="imageContainer"] {
        -webkit-column-count: 1;
                column-count: 1;
    }
}

/*Grid Layout for small screens*/
@media only screen and (max-width: 1024px) and  (min-width: 512px) {
    [class*="imageContainer"] {
        -webkit-column-count: 2;
                column-count: 2;
    }
}

/*Grid Layout for medium screens*/
@media only screen and (max-width: 2048px)  and  (min-width: 1024px) {
    [class*="imageContainer"] {
        -webkit-column-count: 4;
                column-count: 4;
    }
}

modal-backdrop {
    background-color: transparent !important;
  }
  .closeButton {
    position: absolute;
    top: -20%;
    right: -10%;
    cursor: pointer;
}

.centerText{
    text-align: center;
    display: block;
}
.videoWrapper {
    position: relative;
    height: 0;
    padding-bottom: calc(.5625 * 100%);
    padding-bottom: calc(var(--aspect-ratio, .5625) * 100%); 

  }
  .videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

.videoModal {
    background: rgb(0, 0, 0, .8);


}
.videoButton {
    font-size: 4rem;
    color: #fff;
}
.videoButton:hover {
    font-size: 4rem;
    color: #ff9800;
}
.closeButton {
    position: absolute;
    top: -20%;
    right: -10%;
    cursor: pointer;
}

.videoImg {
    position: relative;
    padding: 5px;
    border-bottom-left-radius: 5%;
    border-top-right-radius: 5%;
    border-bottom-right-radius: 5%;
    border-top-left-radius: 5%;


}

.videoImg:hover {
    background: #ff9800;
    cursor: pointer;
}

.videoGalWrapper {
    padding: 50px;
}
.borderRow {
    border: 0.5px solid rgba(0, 0, 0, 0.2);
    margin-top: 3em;
    margin-bottom: 2em;
    margin-left: 0.5em;
    margin-right: 0.5em;
}

.imgSize {
    width: 100%;
    height: 300px;
    padding: 0;
    margin: 0;
    background-image: url("/assets/Ashram_India.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.imgNoSpace {
    margin: 0;
    padding: 0;
}

.img-helping {
    width: 100%;
    height: 300px;
    padding: 0;
    margin: 0;
    background-image: url("/assets/Helping_homeless.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.btn-color {
    background-color: #FF851B;
    color: #fff;
}

.img-children {
    width: 100%;
    height: 300px;
    padding: 0;
    margin: 0;
    background-image: url("/assets/helping_children_image.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.img-covid {
    width: 100%;
    height: 300px;
    padding: 0;
    margin: 0;
    background-image: url("/assets/covid_19.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.img-homeless {
    width: 100%;
    height: 300px;
    padding: 0;
    margin: 0;
    background-image: url("/assets/serving_homeless_image.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.img-environment {
    width: 100%;
    height: 300px;
    padding: 0;
    margin: 0;
    background-image: url("/assets/helping_environment_image.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.img-cows {
    width: 100%;
    height: 300px;
    padding: 0;
    margin: 0;
    background-image: url("/assets/cows.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-silver {
    background-color: rgba(0, 0, 0, 0.1);
}

:root {
	--primary-color: #3a4052;
}

.country {
  width: 10;
  height: 10;
}

#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
 
}



.showcase {
  position: relative;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	color: #fff;
  width: 100%;
  
}

video {
position: absolute;
top: 50%;
left: 50%;
transform: translateX(-50%) translateY(-50%);
}



.noPadding {
    margin-top: 0px;
    margin-bottom: 0px;
}

.headingbg {
    text-align: center;
    background: black;
    opacity: 0.5;
    width: 100%;
    height: 100vh;
    position: absolute;
}

.heading1 {
  z-index: 1;
  letter-spacing: 8px;
  font-weight: 300;
	font-size: 60px;
  line-height: 1.2;
  margin-bottom: 300px;
  -webkit-animation: heading1 3s 1;
          animation: heading1 3s 1;
}

.heading2 {
  padding-top: 50px;
  text-transform: uppercase;
  display: block;
  color: #F79F1F;
  -webkit-animation: heading2 3s 1;
          animation: heading2 3s 1;

  
}
.box {
  overflow: hidden;
  top: 10%;
}


@media only screen and (max-width: 1000px) {
  .heading1 {
    z-index: 1;
    margin-left: 10px;
    margin-top: 10%;
    font-size: 5px;
    font-weight: 100;
    -webkit-animation: heading3 3s 1;
            animation: heading3 3s 1;

  }

 
  
  .heading2 {
    text-transform: uppercase;
    display: block;
    color: #F79F1F;
    position: absolute;
    margin-top: 100px;

  -webkit-animation: heading4 3s 1;

          animation: heading4 3s 1;

  }

  


}

@-webkit-keyframes heading1 {
  0%{
    color: black;
    

  }
  30%{
    margin-bottom: -40px;

  }
  50%{
    margin-bottom: 0px;
    color: grey;

  }
  100%{
    margin-top: 0px;

  }
}

@keyframes heading1 {
  0%{
    color: black;
    

  }
  30%{
    margin-bottom: -40px;

  }
  50%{
    margin-bottom: 0px;
    color: grey;

  }
  100%{
    margin-top: 0px;

  }
}
@-webkit-keyframes heading2 {
  0%{
    color: yellow;
    margin-top: -80px;

  }
  30%{
    margin-top: -20px;
    letter-spacing: -2px;


  }
  50%{
    margin-top: -10px;
    letter-spacing: 15px;


  }
  100%{
 
  }
}
@keyframes heading2 {
  0%{
    color: yellow;
    margin-top: -80px;

  }
  30%{
    margin-top: -20px;
    letter-spacing: -2px;


  }
  50%{
    margin-top: -10px;
    letter-spacing: 15px;


  }
  100%{
 
  }
}


@-webkit-keyframes heading3 {
  0%{
    color: black;
    

  }
  30%{
    margin-bottom: -40px;
    color: grey;
  }

  100%{
    margin-top: 10%;

  }
}


@keyframes heading3 {
  0%{
    color: black;
    

  }
  30%{
    margin-bottom: -40px;
    color: grey;
  }

  100%{
    margin-top: 10%;

  }
}
@-webkit-keyframes heading4 {
  0%{
    color: yellow;
    margin-top: -80px;

  }
  30%{
    margin-top: 20px;
    letter-spacing: 15px;


  }
  100%{
    margin-top: 10  0px;


  }

}
@keyframes heading4 {
  0%{
    color: yellow;
    margin-top: -80px;

  }
  30%{
    margin-top: 20px;
    letter-spacing: 15px;


  }
  100%{
    margin-top: 10  0px;


  }

}
Container
{
    margin: 0;
    
}



.tile {

    width: 350px ;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
    top: 0px;
    position: relative;
}

.tileCaption {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    object-fit: cover;
    top: 10px;
    padding: 10px;
    font-size: 18px;
    color: white;
    text-align: center;
    background-color: #f6a965;
    opacity: 0.8;
    font-family: sans-serif;
    text-transform: uppercase;
    letter-spacing: 10px;
    font-weight: 800;
}

.noPadding {
    margin-top: 0px;
    margin-bottom: 0px;
}
/* .paddingTop{
    padding-top: 100px;
} */
.eventImage {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0%;
    border-bottom-right-radius: 0%;
  
}

.textBG {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0%;
    border-bottom-right-radius: 0%;
    text-align: center;
    position: absolute;
    top: 0px;
    left: 0px;
    margin: 0px;
    opacity: 0.4;
    width: 100%;
    height: 100%;
    background-color: black;
    


}
.eventsOverlay {
    position: absolute;
    top: 90%;
    left: 5px;
    transform: translate(0, -90%);
    color: white;
    width: 100%;
    text-align: center;
    
}


.text{
    text-align: center;
    position: absolute;
    top: 20%;
    left: 0px;
    color: white;
    font-size: 20px;
    margin: 0px;
    width: 100%;
    height: 100%;
}
.text:hover{
    color: #FF851B;
}

.col {
    left: 0px;
    top: 0px;
    margin: 0px;

}

@media only screen and (max-width: 1400px) {
    .tile {
        width: 100%;
        height: 40%;
        margin: 0px;
        top: 0px;
        left: 0px;
        position: relative;
        visibility: hidden;
        
    }
  }

  .countryFlagHome{
    
    object-fit: fill;
    
    padding: 0px;
    margin: 0px;
    width: 211px;
    height: 50px;
  
  }

.accordionItem {
    cursor: pointer;

}


.accordionContainer {
    margin: auto;
    padding: 10px;
   
  }
  
  .accordionTitle {
    width: 50%;
    float: left;
    font-family: sans-serif;
    font-weight: bold;
    text-align: left;
    color: #6c757d;
  }
  
  .accordionDate {
    margin-left: 15%;

    font-family: sans-serif;
    font-weight: bold;
    text-align: right;
    color: #6c757d;
    
  }
  .upcomingEvents{
      margin-top: 4%;
      margin-left: 5%;
      margin-right: 5%;
  }
  .accordionDetails {
    background-image: linear-gradient(to right, #dde0e3 , grey);

}

