.countryName {
    font-weight: bold;
    text-transform: uppercase;
    color: #0074D9;
}

.headingText {
    color: #0074D9;
    text-transform: uppercase;
}

.line{
    border: 1px solid #FF851B;
    width: 100px;
    margin-top: 0;
}

.address {
    font-style: italic;
    color: rgba(0, 0, 0, 0.7);
    font-family: sans-serif;
}

.addressPadding{
  padding-top: 1em;
}

.mapBorder{
  border: 1px solid rgba(0,0,0,0.1);
}

.topHeading {
    padding: 20px;
}

.customPadding {
    padding: 20px;
}

.cardsMargin{
    margin: 1em 0;
}

.marginSpace{
    margin-top: 2em;
    margin-bottom: 2em;
    margin-left: auto;
    margin-right: auto;
    padding-top: 2em;
    padding-bottom: 3em;
}

.contactImage {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    background-image: url("/assets/Ashram_India.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.rowPadding {
    padding: auto;
    margin: 0;
}

.headingMargin{
    margin-top: 30px;
}

.cardsPadding {
    margin-left: auto;
    margin-right: auto;
    padding-top: 1em;
    padding-bottom: 4em;
}

.centerText {
    text-align: center;
}

.contactFormPadding{
    margin-left: auto;
    margin-right: auto;
    padding-top: 2em;
    padding-bottom: 8em;
}



.formStyle{
    width: 50%;
}

.orangeBack {
    background-color: #FF851B;
}

.silverBack{
    background-color:  #d7dbdd  ;
}

.bg-branches{
    background-color: #f6ddcc;
}

.imgNoSpace {
    margin: 0;
    padding: 0;
}

.bg-contact{
    background-color: rgba(0, 0, 0, 0.1);
}

.btn-color {
    background-color: #FF851B;
    color: #fff;
}
.countryName{
    color: #0d47a1;
    text-align: left;
    text-transform: uppercase;
    
}
.lineSep {
    background-color: #0d47a1;
    width:50%;
    text-align:center;
}
.flagDiv{
    object-fit: cover;
    margin-right: 50px;
    margin-top: 5px;

}

.flagImg{
    display: block;
    background-color: #FF851B;
    max-width:150px;
    max-height:auto;
    width: auto;
    height: auto;
   
}