.nav
{  
    opacity: 0.9;  
    width: 100%;   
    z-index: 2;   
    position: fixed;
    top: 0;
    background-color: #d7dbddaa;
    
}

.navItem {
    font-size: 18px;
}
.navItem:hover {
    color: #FF851B;
}

.countryLogo {
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 50%;
    border: 3px solid rgba(0, 0, 0, 0);
    
}
.countryLogo:hover {
    border: 3px solid #FF851B;
    cursor: pointer;
    
}

.scountryLogo {
    margin: 10px;
    border-radius: 50%;

    
}
.scountryLogo:hover {
    border: 3px solid #FF851B;
    cursor: pointer;
    
}

.countryModalTitle{
    font-family: sans-serif;
    text-align: center;
    padding-bottom: 20px;
    padding-top: 20px;
    background-color: #d7dbdd;
    color: #6c757d;
    font-weight: bolder;
    font-family: sans-serif;
}

.countryModalBody{
    text-align: center;
    
}



alertMessage {
    position: fixed;
}

.headingText {
    padding-top: 20px;
}
.contactButton {
    margin-left: 10px;
    border-radius: 50px !important;
    height: 40px;
    background-color: #6c757d;
    border-color: #6c757d;
    font-size: 12px;
    font-weight: bolder;
    text-align: left;
}

.contactButton:hover {
    background-color: #FF851B;
    border-color: #FF851B;
}

.contactContainer{
    margin-left: 2%;
    margin-right: 2%;
}


.contactSubmitButton{
    margin-top: 20px;
    text-align: center;
    background-color: #6c757d;
    border-color: #6c757d;
    color: white;
    cursor: pointer;
}

.contactSubmitButton:hover{
    margin-top: 20px;
    text-align: center;
    background-color: #FF851B;
    border-color: #FF851B;
    color: white;
}

.contactModalTitle {
    text-align: center;
    background-color: #d7dbdd;
    color: #6c757d;
    padding-top: 2%;
    padding-bottom: 2%;
    font-family: sans-serif;
    font-weight: bolder;
}

.disabledtrue{
    cursor: not-allowed;
    pointer-events: none;
    color: #d7dbdd;
    background-color: #d7dbdd;
}
.closeButton {

    cursor: pointer;
}

.launchTc {
    display: inline;
    color: #6c757d;
    cursor: pointer;

}
.launchTc:hover {

    color: #FF851B;


}
.tcContainer{
    font-family: sans-serif;
}

ol {
    counter-reset: item
  }
  li {
    display: block
  }
  li:before {
    content: counters(item, ".") " ";
    counter-increment: item
  }
  
