
.disabledCountryGallery {
    opacity: 0.2;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 50px;
    width: 50px;
    height: 50px;

}
.disabledCountryGallery:hover {
    border-color: #fff;
    cursor: not-allowed;
}


.eventGalImg {
    width: 100%;
    height: auto;
    display: block;
    padding: 5px;
    background: #fff;
    box-sizing: border-box;

}

.eventGalImg:hover {
    background: #ff9800;
    cursor: pointer;
}

.imageContainer {
    column-count: 6;
    column-gap: 5px;
    margin: 0 auto;
    background: #fff;;
    padding-left: 10%;
    padding-right: 10%; 
}


.hide{
    display:none;
}
.unhide{
    display: inherit;
}

.imgCountry {
    cursor: pointer;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 50px;
    width: 50px;
    height: 50px;
}
.imgCountry:hover {
    cursor: pointer;
    border: 3px solid #FF851B;
}
.alignRight {
    text-align: right;
}
.imgCountryHeading {
    font-family: sans-serif;
    text-transform: uppercase;
    letter-spacing: 5px;
    font-weight: 800;
    color: #0d47a1;
    align-content: center;
    margin: 50px;
}
.photoModal {
    background: rgb(0, 0, 0, .8);

}
.modalPhoto {
    width: 100%;
    height: auto;
}
.photoButton {
    font-size: 6rem;
    color: #fff;
}
.photoModalBody{
    background: transparent;
}
.leftArrow {
    position: absolute;
    top: 45%;
    left: -20%;
    cursor: pointer;
}
.rightArrow {
    position: absolute;
    top: 45%;
    right: -20%;
    cursor: pointer;
}
.photoCaption{
    position: absolute;
    top: 100%;
    color: #fff;
    cursor: pointer;
    text-align: center;
    font-size: 1.5rem;
}
.photoButton:hover {
    color: #ff9800;
}
.headingText {
    color: #0d47a1; 
}
.topPad {
    padding-top: 50px;
}



/*Grid Layout for tiny screens*/
@media only screen and (max-width: 512px)  {
    [class*="imageContainer"] {
        column-count: 1;
    }
}

/*Grid Layout for small screens*/
@media only screen and (max-width: 1024px) and  (min-width: 512px) {
    [class*="imageContainer"] {
        column-count: 2;
    }
}

/*Grid Layout for medium screens*/
@media only screen and (max-width: 2048px)  and  (min-width: 1024px) {
    [class*="imageContainer"] {
        column-count: 4;
    }
}

modal-backdrop {
    background-color: transparent !important;
  }
  .closeButton {
    position: absolute;
    top: -20%;
    right: -10%;
    cursor: pointer;
}

.centerText{
    text-align: center;
    display: block;
}