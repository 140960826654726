.borderRow {
    border: 0.5px solid rgba(0, 0, 0, 0.2);
    margin-top: 3em;
    margin-bottom: 2em;
    margin-left: 0.5em;
    margin-right: 0.5em;
}

.imgSize {
    width: 100%;
    height: 300px;
    padding: 0;
    margin: 0;
    background-image: url("/assets/Ashram_India.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.imgNoSpace {
    margin: 0;
    padding: 0;
}

.img-helping {
    width: 100%;
    height: 300px;
    padding: 0;
    margin: 0;
    background-image: url("/assets/Helping_homeless.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.btn-color {
    background-color: #FF851B;
    color: #fff;
}

.img-children {
    width: 100%;
    height: 300px;
    padding: 0;
    margin: 0;
    background-image: url("/assets/helping_children_image.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.img-covid {
    width: 100%;
    height: 300px;
    padding: 0;
    margin: 0;
    background-image: url("/assets/covid_19.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.img-homeless {
    width: 100%;
    height: 300px;
    padding: 0;
    margin: 0;
    background-image: url("/assets/serving_homeless_image.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.img-environment {
    width: 100%;
    height: 300px;
    padding: 0;
    margin: 0;
    background-image: url("/assets/helping_environment_image.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.img-cows {
    width: 100%;
    height: 300px;
    padding: 0;
    margin: 0;
    background-image: url("/assets/cows.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-silver {
    background-color: rgba(0, 0, 0, 0.1);
}