.rowSpacing{
    padding: 50px auto;
    margin: 20px auto;
    text-align: justify;;
}
.headingText{
    color: #0d47a1;
}
.aboutParagraph{

}