Container
{
    margin: 0;
    
}



.tile {

    width: 350px ;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
    top: 0px;
    position: relative;
}

.tileCaption {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    object-fit: cover;
    top: 10px;
    padding: 10px;
    font-size: 18px;
    color: white;
    text-align: center;
    background-color: #f6a965;
    opacity: 0.8;
    font-family: sans-serif;
    text-transform: uppercase;
    letter-spacing: 10px;
    font-weight: 800;
}

.noPadding {
    margin-top: 0px;
    margin-bottom: 0px;
}
/* .paddingTop{
    padding-top: 100px;
} */
.eventImage {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0%;
    border-bottom-right-radius: 0%;
  
}

.textBG {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0%;
    border-bottom-right-radius: 0%;
    text-align: center;
    position: absolute;
    top: 0px;
    left: 0px;
    margin: 0px;
    opacity: 0.4;
    width: 100%;
    height: 100%;
    background-color: black;
    


}
.eventsOverlay {
    position: absolute;
    top: 90%;
    left: 5px;
    transform: translate(0, -90%);
    color: white;
    width: 100%;
    text-align: center;
    
}


.text{
    text-align: center;
    position: absolute;
    top: 20%;
    left: 0px;
    color: white;
    font-size: 20px;
    margin: 0px;
    width: 100%;
    height: 100%;
}
.text:hover{
    color: #FF851B;
}

.col {
    left: 0px;
    top: 0px;
    margin: 0px;

}

@media only screen and (max-width: 1400px) {
    .tile {
        width: 100%;
        height: 40%;
        margin: 0px;
        top: 0px;
        left: 0px;
        position: relative;
        visibility: hidden;
        
    }
  }

  .countryFlagHome{
    
    object-fit: fill;
    
    padding: 0px;
    margin: 0px;
    width: 211px;
    height: 50px;
  
  }
