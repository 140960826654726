.accordionItem {
    cursor: pointer;

}


.accordionContainer {
    margin: auto;
    padding: 10px;
   
  }
  
  .accordionTitle {
    width: 50%;
    float: left;
    font-family: sans-serif;
    font-weight: bold;
    text-align: left;
    color: #6c757d;
  }
  
  .accordionDate {
    margin-left: 15%;

    font-family: sans-serif;
    font-weight: bold;
    text-align: right;
    color: #6c757d;
    
  }
  .upcomingEvents{
      margin-top: 4%;
      margin-left: 5%;
      margin-right: 5%;
  }
  .accordionDetails {
    background-image: linear-gradient(to right, #dde0e3 , grey);

}
