
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap');

:root {
	--primary-color: #3a4052;
}

.country {
  width: 10;
  height: 10;
}

#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
 
}



.showcase {
  position: relative;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	color: #fff;
  width: 100%;
  
}

video {
position: absolute;
top: 50%;
left: 50%;
transform: translateX(-50%) translateY(-50%);
}



.noPadding {
    margin-top: 0px;
    margin-bottom: 0px;
}

.headingbg {
    text-align: center;
    background: black;
    opacity: 0.5;
    width: 100%;
    height: 100vh;
    position: absolute;
}

.heading1 {
  z-index: 1;
  letter-spacing: 8px;
  font-weight: 300;
	font-size: 60px;
  line-height: 1.2;
  margin-bottom: 300px;
  animation: heading1 3s 1;
}

.heading2 {
  padding-top: 50px;
  text-transform: uppercase;
  display: block;
  color: #F79F1F;
  animation: heading2 3s 1;

  
}
.box {
  overflow: hidden;
  top: 10%;
}


@media only screen and (max-width: 1000px) {
  .heading1 {
    z-index: 1;
    margin-left: 10px;
    margin-top: 10%;
    font-size: 5px;
    font-weight: 100;
    animation: heading3 3s 1;

  }

 
  
  .heading2 {
    text-transform: uppercase;
    display: block;
    color: #F79F1F;
    position: absolute;
    margin-top: 100px;

  animation: heading4 3s 1;

  }

  


}

@keyframes heading1 {
  0%{
    color: black;
    

  }
  30%{
    margin-bottom: -40px;

  }
  50%{
    margin-bottom: 0px;
    color: grey;

  }
  100%{
    margin-top: 0px;

  }
}
@keyframes heading2 {
  0%{
    color: yellow;
    margin-top: -80px;

  }
  30%{
    margin-top: -20px;
    letter-spacing: -2px;


  }
  50%{
    margin-top: -10px;
    letter-spacing: 15px;


  }
  100%{
 
  }
}


@keyframes heading3 {
  0%{
    color: black;
    

  }
  30%{
    margin-bottom: -40px;
    color: grey;
  }

  100%{
    margin-top: 10%;

  }
}
@keyframes heading4 {
  0%{
    color: yellow;
    margin-top: -80px;

  }
  30%{
    margin-top: 20px;
    letter-spacing: 15px;


  }
  100%{
    margin-top: 10  0px;


  }

}